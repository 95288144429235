

@media screen and (min-width:600px) {
    .flexoHeaderBusca2{
        flex-direction: row!important;
        align-items: center!important;
        margin-top: 48px!important;
        margin-bottom: 12px!important;
    }
.widthSearchContact{
    width: auto !important;
}

.contenedorSearchContactos{
    flex-direction: row!important;
    margin-top: 0px!important;
    margin-bottom: 12px!important;
}
   
}

.margeneoAutoContac{
    margin-top: auto!important;
    margin-bottom: auto!important;
   
}

    

@media screen and  (min-width: 600px) {
    .margeneoBotonesBuscadorDer{
        margin-top: 0!important;
        margin-left: 8px!important;
        margin-right: 8px!important;
    }
}

.botonRaroSpaceX{
    --tw-space-x-reverse: 0!important;
    margin-left: calc(1.2rem*(1 - var(--tw-space-x-reverse)))!important;
    margin-right: calc(1.2rem*var(--tw-space-x-reverse))!important;
}

.hovereoListoca2{
    border-radius: 0px !important;
}

.hovereoListoca2:hover{
    background-color: rgba(0, 0, 0, 0.05)!important;
    cursor:pointer !important;
}